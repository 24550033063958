@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap');

/* 
====== 
Variables 
======
*/

:root {
  --appColor: #7ea8be;
}

/* 
====== 
Global Styles 
======
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  overscroll-behavior: none;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

.navbar__li {
  @apply block py-2 pr-4 pl-3 text-gray-700 rounded xl:hover:bg-gray-200 xl:hover:bg-transparent xl:hover:text-blue-700 xl:p-0;
}

.section_title {
  @apply text-2xl md:text-4xl xl:text-5xl mb-8 font-bold;
}

.section_text {
  @apply text-base xl:text-xl;
}

.service_img {
  @apply brightness-50 rounded-xl hover:transition hover:brightness-90 max-h-[31.25rem];
}

.sliderTypewriter__btns {
  @apply justify-center items-center rounded-md border-indigo-900 xl:hover:border-none xl:hover:bg-indigo-900 xl:hover:border-transparent xl:hover:bg-opacity-50  transition-all duration-500;
}

.contact__input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-indigo-900 transition duration-500;
}

@layer utilities {
  .clip-insert {
    clip-path: inset(0);
  }
}
